import { Card } from "antd";
import React from "react";
import { PackageType } from "../../utils/types";
import Image from "./Image";

const Package: React.FC<{ photographyPackage: PackageType }> = ({
  photographyPackage,
}) => {
  const { image, title, description, details, price } = photographyPackage;
  return (
    <Card
      title={<strong style={{ whiteSpace: "normal" }}>{title}</strong>}
      cover={
        <Image
          src={image?.fixed.src || ""}
          placeholder={image?.fixed.src || ""}
          style={{ height: 300, width: "100%" }}
        />
      }
    >
      <p>{description?.description}</p>
      <p>{details}</p>
      <h3>{price}</h3>
    </Card>
  );
};

export default Package;
