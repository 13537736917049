import { Col, Divider, Row, Space } from "antd";
import Avatar from "antd/lib/avatar/avatar";

import React from "react";
import { useFacebookData, useInstagramData } from "../../utils/media";
import { MediaKitSectionType } from "../../utils/types";
import RichText from "../ui/RichText";

const Metric: React.FC<{ metric: MetricType }> = ({ metric }) => {
  if (!metric.summary) return null;
  return (
    <Space direction="vertical">
      <p style={{ maxWidth: 200, textAlign: "center" }}>{metric.label}</p>
      <h2 style={{ textAlign: "center", fontSize: 40 }}>{metric.summary}</h2>
    </Space>
  );
};

export type MetricType = {
  label: string;
  summary?: string | number;
};

const MediaKitSection: React.FC<{ section: MediaKitSectionType }> = ({
  section,
}) => {
  const { instagramData } = useInstagramData();
  const { facebookData } = useFacebookData();

  console.log({ instagramData, facebookData, section });

  const metrics: MetricType[] = [
    {
      label: "Instagram",
      summary: instagramData
        ? `${(instagramData.followers / 1000).toFixed(1)}k`
        : "Unable to fetch",
    },
    // { label: "Instagram (past week)", summary: "+3" },
    {
      label: "Facebook",
      summary: facebookData
        ? `${facebookData.fan_count} likes`
        : "Unable to fetch",
    },
    {
      label: "Facebook (past week)",
      summary: facebookData
        ? facebookData.talking_about_count
        : "Unable to fetch",
    },
  ];

  return (
    <>
      <Row justify="center" align="middle" style={{ margin: "3rem 0" }}>
        <div
          style={{
            borderRadius: 200,
            padding: 5,
            background:
              "linear-gradient(45deg, #f09433 20%,#e6683c 30%,#dc2743 50%,#cc2366 60%,#bc1888 80%)",
          }} // Instagram Gradient Background
        >
          <Avatar
            size={100}
            style={{ border: "4px solid white" }}
            src="https://instagram.fmel7-1.fna.fbcdn.net/v/t51.2885-19/s150x150/64444484_376477626553760_8270093101930905600_n.jpg?_nc_ht=instagram.fmel7-1.fna.fbcdn.net&_nc_ohc=kpeW9mO5mX8AX9R-s9H&oh=ba6145f6bc63490ba0fa6977f8fced3a&oe=5FB94DEF"
          />
        </div>
        <div style={{ marginLeft: 30 }}>
          <h1>Andrew Faram</h1>
          {section.description && <RichText data={section.description.json} />}
        </div>
      </Row>

      <Row justify="center" gutter={[40, 40]}>
        {metrics.map((metric) => (
          <Col key={metric.label}>
            <Metric metric={metric} />
          </Col>
        ))}
      </Row>
      <div />
      <Divider />
    </>
  );
};
export default MediaKitSection;
