import Title from "antd/lib/typography/Title";
import React from "react";
import { BookingFormSectionType } from "../../utils/types";
import Form from "../ui/Form";
import RichText from "../ui/RichText";
import Space from "../ui/Space";

const BookingFormSection: React.FC<{
  section: BookingFormSectionType;
  photographyPackage?: string;
}> = ({ section, photographyPackage }) => {
  const { title, description } = section;
  return (
    <Space>
      <Title>{title}</Title>
      <br />
      <RichText data={description?.json} />
      <Form photographyPackage={photographyPackage} />
    </Space>
  );
};

export default BookingFormSection;
