import { Col, Divider, Row } from "antd";
import React from "react";
import { PackagesSectionType } from "../../utils/types";
import Package from "../ui/Package";

const PackagesSection: React.FC<{
  section: PackagesSectionType;
  setPhotographyPackage: (title: string) => void;
  currentPhotographyPackage?: string;
}> = ({ section, setPhotographyPackage, currentPhotographyPackage }) => {
  return (
    <>
      <Row justify="center" gutter={[20, 20]}>
        {section.packages.map((photographyPackage) => {
          const isActive =
            photographyPackage.title === currentPhotographyPackage;

          return (
            <Col
              key={photographyPackage.id}
              xs={24}
              sm={24}
              md={8}
              onClick={() => setPhotographyPackage(photographyPackage.title)}
            >
              <div
                style={{
                  border: isActive ? "2px solid #333" : "2px solid transparent",
                  transition: "border 0.1s ease",
                  cursor: "pointer",
                }}
              >
                <Package photographyPackage={photographyPackage} />
              </div>
            </Col>
          );
        })}
      </Row>
      <Divider />
    </>
  );
};

export default PackagesSection;
