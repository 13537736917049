import styled from "@emotion/styled";
import React, { useState } from "react";
import {
  BookingFormSectionType,
  ClientsSectionType,
  MediaKitSectionType,
  PressReleaseSectionType,
  PackagesSectionType,
  SectionType,
  StoreSectionType,
  VideographySectionType,
} from "../../utils/types";
import BookingFormSection from "./BookingFormSection";
import ClientsSection from "./ClientsSection";
import MediaKitSection from "./MediaKitSection";
import PackagesSection from "./PackagesSection";
import PressReleaseSection from "./PressReleaseSection";
import StoreSection from "./StoreSection";
import VideographySection from "./VideographySection";

const StyledSection = styled.div`
  /* @media (max-width: 1000px) {
    padding: 40px;
    & * {
      max-width: max-content;
      max-width: -webkit-fill-available;
    }
  } */
`;

const Sections: React.FC<{ sections: SectionType[] }> = ({ sections }) => {
  const [photographyPackage, setPhotographyPackage] = useState<string>();
  return (
    <StyledSection>
      {sections.map((section) => {
        if (section.internal.type === "ContentfulBookingFormSection")
          return (
            <BookingFormSection
              key={section.id}
              photographyPackage={photographyPackage}
              section={section as BookingFormSectionType}
            />
          );
        if (section.internal.type === "ContentfulClientsSection")
          return (
            <ClientsSection
              key={section.id}
              section={section as ClientsSectionType}
            />
          );
        if (section.internal.type === "ContentfulMediaKitSection")
          return (
            <MediaKitSection
              key={section.id}
              section={section as MediaKitSectionType}
            />
          );
        if (section.internal.type === "ContentfulPackagesSection")
          return (
            <PackagesSection
              key={section.id}
              currentPhotographyPackage={photographyPackage}
              setPhotographyPackage={(title) => setPhotographyPackage(title)}
              section={section as PackagesSectionType}
            />
          );
        if (section.internal.type === "ContentfulPressReleaseSection")
          return (
            <PressReleaseSection
              key={section.id}
              section={section as PressReleaseSectionType}
            />
          );
        if (section.internal.type === "ContentfulStoreSection")
          return (
            <StoreSection
              key={section.id}
              section={section as StoreSectionType}
            />
          );
        if (section.internal.type === "ContentfulVideographySection")
          return (
            <VideographySection
              key={section.id}
              section={section as VideographySectionType}
            />
          );
      })}
    </StyledSection>
  );
};

export default Sections;
