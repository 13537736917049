import React from "react";
import { Input as InputComponent } from "antd";
import { useFormikContext } from "formik";
import { useResponsive } from "../../../utils/responsive";

const { TextArea: TextAreaComponent } = InputComponent;

const inputStyle = {
  borderRadius: 0,
  padding: "10px 20px",
  // minWidth: 600,
  maxWidth: "80vw",
};

export const Input = (props: any) => {
  const { name, value, placeholder } = props;
  const { setFieldValue } = useFormikContext();
  const { isMobile } = useResponsive();

  return (
    <InputComponent
      type="block"
      name={name}
      value={value}
      style={{ ...inputStyle, width: (isMobile && "100%") || undefined }}
      placeholder={placeholder}
      onChange={(e) => setFieldValue(name, e.target.value)}
    />
  );
};

export const TextArea = (props: any) => {
  const { name, value, placeholder } = props;
  const { setFieldValue } = useFormikContext();
  const { isMobile } = useResponsive();

  return (
    <TextAreaComponent
      autoSize
      style={{ ...inputStyle, width: (isMobile && "100%") || undefined }}
      name={name}
      value={value}
      placeholder={placeholder}
      onChange={(e) => setFieldValue(name, e.target.value)}
    />
  );
};
