import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import styled from "@emotion/styled";
import React from "react";

const StyledRichText = styled.div`
  @media (max-width: 1000px) {
    & * {
      max-width: calc(100vw - 3rem);
      position: relative;
    }
  }
`;

// import { BLOCKS, MARKS } from "@contentful/rich-text-types"

// const options = {
//       renderMark: {
//             [MARKS.BOLD]: text => <strong>{text}</strong>
//       },
//       renderNode: {
//             [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>
//       },
// }

// Data takes RichText JSON Format
const RichText: React.FC<{ data: any }> = ({ data }) => {
  console.log(data);
  if (!data) return null;
  return (
    <StyledRichText
    // style={{
    //   textAlign: JSON.stringify(data).length < 1000 ? "center" : "left",
    // }}
    >
      {documentToReactComponents(data)}
    </StyledRichText>
  );
};

export default RichText;
