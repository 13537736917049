import styled from "@emotion/styled";
import React from "react";

const StyledSpace = styled.div<{ size?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  & > * {
    margin: ${(props) => `${props.size}px 0`};
  }
`;

interface Props {
  children: React.ReactNode;
  size?: number;
}

const Space: React.FC<Props> = ({ size = 0, children }) => {
  return <StyledSpace size={size}>{children}</StyledSpace>;
};

export default Space;
