import { useState, useEffect } from "react";
import axios, { AxiosRequestConfig } from "axios";

// Facebook App Config
// const FACEBOOK_APP_ID = "2720557468218689";
// const FACEBOOK_APP_SECRET = "e42e56f2cd8249d1eac2dc8c21bcee1e";
// const FACEBOOK_USER_ACCESS_TOKEN =
//   "EAAmqVSV79UEBAIzMSzP9P4jDy9eKzpCZBhIXgqvN4qWKOJfI9IfJTROi3w5yODkQk6Voxil6BGvLFIHtNmZAjCKdQNkenY9szqVfObxXZAiCaNEvPSgFUaZAuIVT7pKB0IfinD4MYZBA7m4v9KWCkcUlzNUy1J9K7XsmJ4xE9pBIFDGc9mKJEGqSSq6u4zMeG3C8KKjuq9wZDZD"; // FIXME generate token from https://developers.facebook.com/tools/explorer?method=GET&path=345307846023178%3Ffields%3Dfan_count%2Cnew_like_count%2Ctalking_about_count%2Caccess_token%2Cpage_token&version=v8.0

const FACEBOOK_PAGE_ID = "345307846023178";
const FACEBOOK_LONGLIVED_USER_ACCESS_TOKEN =
  "EAAmqVSV79UEBAFgLfWDT30EEXOXzUNdrMbkdpR8aOs78hWqnuiNhDG7czCLySZCISeA8XleVZCADCl2dOwEHlCmoQXUerb5MvPie5kaCCa1oYI5IF5FkIiOyK9w3d8BXOsqwaBlafiOCZBBuIg1vBAkhKKM1b5bZBGT51VTB9QZDZD";

export const useInstagramData = () => {
  const [accountData, setAccountData] = useState<
    { followers: number } | undefined
  >(undefined);
  const getInstagram = async () => {
    const res = await axios.get(
      `https://www.instagram.com/andrewfaramphotography/?__a=1`
    );
    setAccountData({
      followers: res.data.graphql.user.edge_follow.count,
      // followersPastWeek: res.data.graphql.user.edge
    });
  };

  useEffect(() => {
    getInstagram();
  }, []);

  console.log(accountData);
  return { instagramData: accountData };
};

export const useFacebookData = () => {
  const [accountData, setAccountData] = useState<any | undefined>(undefined);

  // const getTokenOptions: AxiosRequestConfig = {
  //   method: "GET",
  //   url: `https://graph.facebook.com/v8.0/oauth/access_token`, // page access token
  //   params: {
  //     grant_type: "fb_exchange_token",
  //     client_id: FACEBOOK_APP_ID,
  //     client_secret: FACEBOOK_APP_SECRET,
  //     fb_exchange_token: FACEBOOK_USER_ACCESS_TOKEN,
  //   },
  // };

  // const getToken = async () =>
  //   await axios
  //     .request(getTokenOptions)
  //     .then((res) => {
  //       console.log(res);
  //       return res.data.access_token;
  //     })
  //     .catch((err) => console.log(err)); // FIXME Use this to get the long lived access token.

  const getFacebookData = async () => {
    const getPageDataTokenOptions: AxiosRequestConfig = {
      method: "GET",
      url: `https://graph.facebook.com/${FACEBOOK_PAGE_ID}`,
      params: {
        fields: "new_like_count,fan_count,talking_about_count",
        access_token: FACEBOOK_LONGLIVED_USER_ACCESS_TOKEN,
      },
      headers: { "content-type": "application/json" },
    };

    const res = await axios.request(getPageDataTokenOptions);

    if (res) setAccountData(res.data);
    else throw new Error("Facebook wasn't able to get that data");
  };

  console.log(accountData);

  useEffect(() => {
    getFacebookData();
  }, []);

  return { facebookData: accountData };
};
