import React from "react";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";

import "./calendar.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useAppointments } from "../../utils/appointments";

const localizer = momentLocalizer(moment);

const Calendar: React.FC<{ date?: Date; setDate: (date: Date) => void }> = ({
  date,
  setDate,
}) => {
  const { appointments } = useAppointments();

  if (!BigCalendar) return null;

  return (
    <BigCalendar
      selectable
      getNow={() => moment(date).toDate()}
      onSelectSlot={(slotInfo) => {
        setDate(slotInfo.start);
      }}
      min={new Date()}
      localizer={localizer}
      events={appointments}
      startAccessor="start"
      endAccessor="end"
      defaultView="month"
      views={["month"]}
      culture="en"
      style={{
        height: 300,
        width: 500,
      }}
    />
  );
  // return <DatePicker />;
};

export default Calendar;
