import { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";

export type AppointmentType =
  | {
      start: Date;
      end: Date;
      title?: string;
      summary?: string;
    }
  | undefined;

const GOOGLE_CALENDAR_ID = "andrew.tj.faram@gmail.com";
const GOOGLE_CALENDAR_API_KEY = "AIzaSyAz3iGDIolLhAmk48ryIEMHLxIzzWQAQGg";

const GOOGLE_CALENDAR_URL = `https://www.googleapis.com/calendar/v3/calendars/${GOOGLE_CALENDAR_ID}/events?key=${GOOGLE_CALENDAR_API_KEY}`;

export const useAppointments: () => {
  appointments: AppointmentType[];
} = () => {
  const [appointments, setAppointments] = useState<AppointmentType[]>([]);

  useEffect(() => {
    const getAppointments = async () => {
      const res = await axios.get(GOOGLE_CALENDAR_URL);

      console.log(res);

      if (res.status === 200) {
        const events: AppointmentType[] = res.data.items.map((appointment) => {
          const start = new Date(appointment.start?.dateTime?.toString());
          const end = new Date(appointment.end?.dateTime?.toString());
          return {
            start,
            end,
            title: appointment.title,
            summary: appointment.summary,
          };
        });

        const orderedAppointments = events.sort(
          (e1, e2) => moment(e1?.start).valueOf() - moment(e2?.start).valueOf()
        );

        setAppointments(orderedAppointments);
      } else {
        console.log(res.status);
      }
    };

    getAppointments();
  }, []);

  return { appointments };
};
