import React, { useState } from "react";
import * as Yup from "yup";

import { Formik, Form as FormikForm, Field } from "formik";
import {
  Form as FormComponent,
  Row,
  Button,
  Tooltip,
  Alert,
  Col,
  Select,
  Input,
} from "antd";
import { TextArea } from "./components";
import Calendar from "../Calendar";
import { useResponsive } from "../../../utils/responsive";
import moment from "antd/node_modules/moment";
import { useAppointments } from "../../../utils/appointments";

const encode = (data) =>
  Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join("&");

const hourSlots: number[] = [9, 10, 11, 12, 13, 14, 15, 16];

const getHourOptions = (date, appointments) => {
  const availableSlots = hourSlots.filter((hourSlot) => {
    if (appointments.length < 1) return true;
    return appointments.some((appointment) => {
      const currentHour = moment(date)
        .startOf("day")
        .add(hourSlot, "hours")
        .hour();

      return moment(appointment?.start).hour() !== currentHour;
    });
  });

  const hourOptions = hourSlots
    .filter((slot) => availableSlots.includes(slot))
    .map((slot) => {
      const currentHour = moment()
        .startOf("day")
        .add(slot, "hours")
        .format("ha");
      const nextHour = moment()
        .startOf("day")
        .add(slot + 1, "hours")
        .format("ha");
      return `${currentHour} - ${nextHour}`;
    });
  return hourOptions;
};

const fields = [
  {
    label: "Your Name",
    name: "name",
    initialValue: "",
    placeholder: "Name",
    required: true,
    component: Input,
  },
  {
    label: "Email Address",
    name: "email",
    initialValue: "",
    placeholder: "example@gmail.com",
    required: true,
    component: Input,
  },
  {
    label: "Phone Number",
    name: "phone",
    initialValue: "Phone Number",
    placeholder: "XX XXXX XXXX",
    required: false,
    component: Input,
  },
  {
    label: "Appointment Time",
    name: "appointment_time",
    initialValue: "Date, Time",
    placeholder: "Date, Time",
    required: false,
    component: Input,
  },
  {
    label: "Message",
    name: "message",
    initialValue: "",
    placeholder: "Write your message here",
    required: true,
    component: TextArea,
  },
  // {
  //   label: "Estimated Budget",
  //   name: "budget",
  //   initialValue: "",
  //   placeholder: "Estimated Budget",
  //   required: false,
  //   component: Input,
  // },
];

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const ValidationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  email: Yup.string().email().required("Required"),
  phone: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
  message: Yup.string().required("Required"),
});

const Form: React.FC<{ photographyPackage?: string }> = ({
  photographyPackage = undefined,
}) => {
  const { isMobile } = useResponsive();
  const { appointments } = useAppointments();
  const [date, setDate] = useState<Date>();

  const getDates = () => {
    const startOfMonth = moment().add(1, "day").startOf("day");
    const endOfMonth = moment().add(2, "week").endOf("day");

    const days: string[] = [];
    let day = startOfMonth;

    while (day <= endOfMonth) {
      days.push(day.toString());
      day = day.clone().add(1, "d");
    }
    return days;
  };

  return (
    <Row justify="center" gutter={[20, 20]} style={{ width: "inherit" }}>
      {!isMobile && (
        <Col
          md={12}
          style={{
            display: "flex",
            justifyContent: "center",
            padding: 20,
            alignItems: "center",
          }}
        >
          <Calendar date={date} setDate={setDate} />
        </Col>
      )}
      <Col xs={24} sm={24} md={12}>
        <Formik
          initialValues={{}}
          validateOnBlur
          validationSchema={ValidationSchema}
          onSubmit={(values, actions) => {
            const body = {
              "form-name": "contact",
              ...values,
              photographyPackage,
            };

            fetch("/", {
              method: "POST",
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
              body: encode(body),
            })
              .then(() => {
                alert("Success");
                actions.resetForm();
              })
              .catch(() => {
                alert("Error");
              })
              .finally(() => actions.setSubmitting(false));
          }}
        >
          {({ submitForm, errors, touched, setFieldValue }) => {
            const hasErrors =
              fields.filter((field) => errors[field.name])?.length > 0;
            return (
              <FormikForm name="contact" data-netlify={true}>
                <FormComponent layout="vertical">
                  {fields.map((field) => {
                    const {
                      label,
                      name,
                      required,
                      placeholder,
                      component,
                    } = field;
                    const validate = () => {
                      if (errors[name] && touched[name]) return `warning`;
                      else if (!errors[name] && touched[name]) return `success`;
                      return undefined;
                    };
                    const getErrorMessage = () => {
                      if (errors[name] && touched[name]) return errors[name];
                      return false;
                    };
                    return (
                      <FormComponent.Item
                        label={<p>{label}</p>}
                        hasFeedback
                        required={required}
                        validateStatus={validate()}
                        help={
                          <Tooltip
                            placement="bottom"
                            visible={getErrorMessage()}
                            title={getErrorMessage()}
                          />
                        }
                        key={name}
                      >
                        <Field
                          name={name}
                          placeholder={placeholder}
                          as={component}
                        />
                      </FormComponent.Item>
                    );
                  })}
                  <p>Select Date</p>
                  <Field
                    style={{ width: "100%" }}
                    name="date"
                    value={moment(date).format("D MMMM YYYY")}
                    onChange={(str) => {
                      setDate(moment(str).toDate());
                      setFieldValue("date", moment(str).format("D MMMM YYYY"));
                    }}
                    as={Select}
                  >
                    {getDates().map((d) => (
                      <Select.Option
                        key={d.toString()}
                        value={moment(d).format("D MMMM YYYY")}
                      >
                        {moment(d).format("D MMMM YYYY")}
                      </Select.Option>
                    ))}
                  </Field>
                  <br />
                  <br />

                  <p>Available Hours</p>
                  <Field
                    style={{ width: "100%" }}
                    name="availableHours"
                    as={Select}
                    defaultValue={getHourOptions(date, appointments)[0]}
                    onChange={(v) => setFieldValue("availableHours", v)}
                  >
                    {getHourOptions(date, appointments).map((hour) => (
                      <Select.Option key={hour} value={hour}>
                        {hour}
                      </Select.Option>
                    ))}
                  </Field>
                  <br />
                  <br />

                  {hasErrors ? (
                    <Alert
                      style={{ marginBottom: "3rem" }}
                      message={<p>You have errors in your form</p>}
                      type="warning"
                      showIcon
                    />
                  ) : null}
                  <Row justify="center">
                    <Button
                      block
                      type="primary"
                      onClick={() => submitForm()}
                      style={{ minHeight: 50 }}
                    >
                      Submit
                    </Button>
                  </Row>
                </FormComponent>
              </FormikForm>
            );
          }}
        </Formik>
      </Col>
    </Row>
  );
};

export default Form;
