import React from "react";
import ReactPlayer from "react-player";
import { VideoType } from "../../utils/types";
import RichText from "./RichText";
import Space from "./Space";

const Video: React.FC<{ video: VideoType }> = ({ video }) => {
  const { title, description, link } = video;
  return (
    <Space size={20}>
      <h3>{title}</h3>
      <RichText data={description?.json} />
      <ReactPlayer width={"100%"} url={link} controls />
      <a target="_blank" rel="noreferrer noopener" href={link}>
        Click here to see original: {link}
      </a>
    </Space>
  );
};

export default Video;
