import React from "react";
import { VideographySectionType } from "../../utils/types";
import Video from "../ui/Video";

const VideographySection: React.FC<{ section: VideographySectionType }> = ({
  section,
}) => {
  return (
    <>
      {section.videos.map((video) => (
        <Video key={video.id} video={video} />
      ))}
    </>
  );
};
export default VideographySection;
