import { Button, Card } from "antd";
import React from "react";
import { ProductType } from "../../utils/types";
import Image from "./Image";

const Product: React.FC<{ product: ProductType }> = ({ product }) => {
  const { images, title, description, price, checkoutLink } = product;

  return (
    <Card
      title={<strong>{title}</strong>}
      extra={<h3>${parseFloat(price.toFixed(2))}</h3>}
      cover={
        <Image
          src={images[0].fixed.src}
          placeholder={images[0].fixed.src}
          style={{ width: "100%", height: 400 }}
        />
      }
    >
      <p>{description?.description}</p>
      <Button
        type="primary"
        style={{ minHeight: 60, color: "white" }}
        block
        onClick={() => window.open(checkoutLink)}
      >
        <span style={{ color: "white" }}>Checkout</span>
      </Button>
    </Card>
  );
};

export default Product;
