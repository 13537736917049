import { Row, Col } from "antd";
import React from "react";
import { StoreSectionType } from "../../utils/types";
import Product from "../ui/Product";

const StoreSection: React.FC<{ section: StoreSectionType }> = ({ section }) => {
  return (
    <Row justify="center" gutter={[20, 20]}>
      {section.products.map((product) => (
        <Col key={product.id} xs={24} sm={24} md={12}>
          <Product product={product} />
        </Col>
      ))}
    </Row>
  );
};
export default StoreSection;
