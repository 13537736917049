import { Divider } from "antd";
import React from "react";
import { PressArticleType, PressReleaseSectionType } from "../../utils/types";
import RichText from "../ui/RichText";

const Article: React.FC<{ article: PressArticleType }> = ({ article }) => {
  return (
    <div>
      <h2>{article.title}</h2>
      <RichText data={article.content?.json} />
      <Divider />
    </div>
  );
};

const PressReleaseSection: React.FC<{ section: PressReleaseSectionType }> = ({
  section,
}) => {
  return (
    <>
      {section.articles?.map((article) => {
        return <Article key={article.id} article={article} />;
      })}
    </>
  );
};
export default PressReleaseSection;
