import styled from "@emotion/styled";
import { Row } from "antd";
import React from "react";

const StyledWrapper = styled.div`
  padding: 0 6rem 12rem 6rem;
  max-width: 960px;

  @media (max-width: 1000px) {
    padding: 0 1.5rem;
    max-width: 100vw;
  }
`;

const Wrapper: React.FC = ({ children }) => {
  return (
    <Row justify="center">
      <StyledWrapper>{children}</StyledWrapper>
    </Row>
  );
};

export default Wrapper;
