import { Col, Divider, Row } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import React from "react";
import { ClientsSectionType, ClientType } from "../../utils/types";
import RichText from "../ui/RichText";

const Client: React.FC<{ client: ClientType }> = ({ client }) => {
  return (
    <a href={client.link} target="_blank" rel="noreferrer noopener">
      <Avatar size={150} src={client.logo?.fixed.src} shape="square" />
    </a>
  );
};

const ClientsSection: React.FC<{ section: ClientsSectionType }> = ({
  section,
}) => {
  return (
    <>
      <h2 style={{ textAlign: "center" }}>{section.title}</h2>
      <RichText data={section.description?.json} />
      <Row justify="center" align="middle" gutter={[20, 20]}>
        {section.clients.map((client) => {
          return (
            <Col key={client.id}>
              <Client client={client} />
            </Col>
          );
        })}
      </Row>
      <Divider />
    </>
  );
};
export default ClientsSection;
