import { graphql } from "gatsby";
import React from "react";
import Layout from "../components/Layout";
import Sections from "../components/Sections";
import PageTitle from "../components/ui/PageTitle";
import RichText from "../components/ui/RichText";
import Wrapper from "../components/ui/Wrapper";
import { PageType } from "../utils/types";

const ContentPage: React.FC<{ data: any }> = ({ data }) => {
  const page: PageType | undefined =
    data?.allContentfulContentPage?.edges[0].node;

  if (!page) return null;

  const { title, content, sections } = page;

  return (
    <Layout>
      <Wrapper>
        <PageTitle>{title}</PageTitle>
        <RichText data={content?.json} />
        {sections && <Sections sections={sections} />}
      </Wrapper>
    </Layout>
  );
};

export default ContentPage;

export const query = graphql`
  query ContentPageQuery($id: String!) {
    allContentfulContentPage(filter: { id: { eq: $id } }) {
      edges {
        node {
          id
          title
          content {
            json
          }
          sections {
            ... on ContentfulBookingFormSection {
              internal {
                type
              }
              id
              title
              description {
                description
              }
            }
            ... on ContentfulClientsSection {
              internal {
                type
              }
              id
              title
              description {
                json
              }
              clients {
                id
                name
                link
                logo {
                  fixed {
                    src
                  }
                }
              }
            }
            ... on ContentfulMediaKitSection {
              internal {
                type
              }
              id

              description {
                json
              }
            }
            ... on ContentfulPackagesSection {
              internal {
                type
              }
              id
              packages {
                id
                image {
                  fixed {
                    src
                  }
                }
                title
                description {
                  description
                }
                details
                price
              }
            }
            ... on ContentfulPressReleaseSection {
              internal {
                type
              }
              id
              # articles {
              #   id
              #   title
              #   link
              #   content {
              #     json
              #   }
              # }
            }
            ... on ContentfulStoreSection {
              internal {
                type
              }
              id
              products {
                id
                title
                checkoutLink
                price
                description {
                  description
                }
                images {
                  fixed {
                    src
                  }
                }
              }
            }
            ... on ContentfulVideographySection {
              internal {
                type
              }
              id
              videos {
                id
                title
                description {
                  json
                }
                link
              }
            }
          }
        }
      }
    }
  }
`;
